html {
  position: relative;
  min-height: 100%;
}

body {
  @media screen and (min-width: 768px) {
    margin-bottom: 240px;
  }
}

.navbar-conspro {
  padding-top: 30px;
}

.navbar .nav a {
  color: #242d41;
  text-transform: uppercase;
}

.navbar .nav a.btn-info {
  color: #fff;
  font-weight: 600;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 10px;
  margin-left: 16px;
}

.navbar .nav a.btn-info:hover {
  background-color: #31b0d5;
  color: #fff;
  padding-bottom: 8px;
}

.navbar .nav .active a.btn:after {
  display: none;
}

.container-blue {
  -webkit-backdrop-filter: blur(1.3px);
  backdrop-filter: blur(1.3px);
  background: #242d41 url(hero.jpg) center center no-repeat;
  background-size: cover;
  min-height: 300px;
  padding: 20px 0px 60px;
}

.container-blue h1 {
  color: #fff;
  font-size: 36px;
  font-weight: normal;
  line-height: 42px;
  margin: 0;
}

.container-blue h1 small {
  color: #fff;
  display: block;
  font-size: 28px;
  font-weight: normal;
  line-height: 36px;
  margin-top: 20px;
}

.container-blue p.intro {
  color: #e5e5e5;
}

.container-blue .iphone-wrapper {
  margin-bottom: -300px;
}

.container-gray {
  background-color: #f1f5f6;
  padding-top: 40px;
}

.container-white {
  background-color: #ffffff;
  padding-top: 40px;
}

p.intro {
  color: #666;
  font-size: 18px;
  line-height: 28px;
}

.btn {
  border: none;
  border-radius: 0;
}

.img-responsive {
  display: inline-block;
}

.img-round {
  max-width: 50%;
}

.panel-title {
  font-size: 20px;
  line-height: 28px;
}

.panel-body {
  color: #666;
  font-size: 16px;
  line-height: 24px;
}

.footer {
  background-color: #f1f5f6;
  padding-bottom: 40px;
  padding-top: 40px;
  color: #888;
  margin-top: 120px;

  @media screen and (min-width: 768px) {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: 0;
  }
}

.footer ul {
  padding-left: 0;
  margin-bottom: 20px;
}

.footer ul>li {
  display: inline-block;
}

.footer ul>li+li {
  margin-left: 20px;
}

.footer a {
  color: #666;
  text-transform: uppercase;
}

.page h1,
.page .h1 {
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 16px;
}

.page h1 small,
.page .h1 small {
  display: block;
  margin-top: 8px;
  margin-bottom: 16px;
}

.page h1:after,
.page .h1:after {
  content: " ";
  display: block;
  width: 40px;
  border-top: 4px solid #2b4449;
  margin-top: 16px;
}

.page h3,
.page .h3 {
  font-size: 20px;
  line-height: 28px;
}

.page h3 small,
.page .h3 small {
  display: block;
  margin-top: 8px;
}

.page p,
.page li {
  font-size: 16px;
  line-height: 24px;
}

.page p {
  margin-bottom: 20px;
}
.page .btn {
  font-weight: 600;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .page-home .container-blue {
    margin-bottom: 360px;
  }
  .container-blue h1 {
    font-size: 28px;
    line-height: 36px;
  }
  .container-blue h1 small {
    font-size: 24px;
    line-height: 32px;
  }
  .container-blue .iphone-wrapper {
    margin-bottom: -360px;
    margin-top: 60px;
  }
  .container-blue .iphone-wrapper:after {
    border-top: 3px solid #242d41;
    content: " ";
    display: block;
    margin: 60px auto 0;
    width: 180px;
  }
  .container-blue .iphone-wrapper .img-responsive {
    max-width: 180px;
  }
  .text-right {
    text-align: left;
  }
  .footer ul>li {
    display: block;
    margin-bottom: 5px;
  }
  .footer ul>li+li {
    margin-left: 0;
  }
}
